import React, { useState, useCallback, Fragment } from 'react';
import MaskedInput from 'react-maskedinput';
import { useLocation } from 'react-router-dom';
import * as qs from 'querystring';
import Modals from './modal';
import { useModal } from 'context/modal';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import Autocomplete from './components/Autocomplete';
import { useJobState } from './useJobState';
import { useFetchData } from './useFetchData';
import { useJobController } from './useJobController';
import TooltipTitle from 'components/TooltipTitle';
import RadioButton from 'components/newComponents/RadioButton';
import routes from 'config/routes';
import './App.scss';

const cancelButtonStatuses = [1, 2, 3, 4, 5, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21];
const bookedButtonStatuses = [1, 7, 11, 12, 17, 18, 21];
const confirmButtonStatuses = [2, 11, 17];
const rescheduleButtonStatuses = [1, 2, 3, 7, 21, 22, 23];

function CustomerEditJob() {
  const { search } = useLocation();
  const { job_id = '' } = qs.parse(search.replace('?', ''));

  const [isLoading, setIsLoading] = useState(false);
  const hidePreloader = () => setIsLoading(false);
  const showPreloader = () => setIsLoading(true);

  const data = useFetchData(job_id, { hidePreloader, showPreloader });

  const { state, actions } = useJobState(job_id, data);
  const { handleChangeAddress, handleChange, handleSelect } = actions;

  const { saveChanges, confirmJob, bookingJob, cancelJob, handleReschedule } = useJobController({ hidePreloader, showPreloader });

  const { modalNames, open } = useModal();

  const jobIsCompleted = state.job_status === 6;

  const serverApi = window.location.origin;
  const inventoryLink = `${serverApi}${routes.INVENTORY}?job_id=${state.job_id}`;
  const packingLink = `${serverApi}${routes.PACKING}?job_id=${state.job_id}`;

  const handleRescheduleJob = useCallback(() => {
    open(modalNames.rescheduleJob, {
      requiredPickup: state.required_pickup,
      jobId: state.job_id,
      onReschedule: handleReschedule,
    });
  }, [modalNames.rescheduleJob, open, state.required_pickup, state.job_id, handleReschedule]);

  const {
    name,
    email,
    phone,
    address_from,
    zip_from,
    address_to,
    zip_to,
    distance,
    note,
    movers,
    trucks,
    estimated_cost,
    rate,
    av_time1,
    av_time2,
    packing,
    inventory,
    extra,
    include,
    full_packing_rate,
    credit_rate,
    company_credit_rate,
    travel_money,
    total_packing,
    job_fees,
    job_charges,
    manual_charge,
    manual_discount,
    job_discounts,
    tips,
    contract_total,
    addendum_total,
    estimated_hours,
  } = state;

  const job_fees_elements = job_fees.map((item, key) => (
    <p key={'job_fees_' + key}>
      <span className="title">{item.name}</span>
      <span className="quantity">${Number(item.amount).toFixed(2)}</span>
    </p>
  ));

  const job_charges_elements = job_charges.map((item, key) => (
    <p key={'job_charges_' + key}>
      <span className="title">{item.name}</span>
      <span className="quantity">${Number(item.amount).toFixed(2)}</span>
    </p>
  ));

  const job_discounts_elements = job_discounts.map((item, key) => (
    <p key={'job_charges_' + key}>
      <span className="title">{item.name}</span>
      <span className="quantity">${Number(item.amount).toFixed(2)}</span>
    </p>
  ));

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <div className="customer-edit-job-container">
        <Modals />

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="new-table">
                <div className="new-table--row">
                  <div className="new-table--row--item new-table--row--item__full no-side-shadows">
                    <h3 className="header">Edit your move info</h3>
                  </div>
                </div>

                <div className="new-table--row mobile">
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <input type="text" disabled={jobIsCompleted} value={name} name="name" placeholder="Enter Name" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <input type="text" disabled={jobIsCompleted} value={email} name="email" placeholder="Enter Email" onChange={handleChange} />
                    </div>
                  </div>
                </div>

                <div className="new-table--row mobile">
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <MaskedInput disabled={jobIsCompleted} mask="111-111-1111" name="phone" value={phone || ''} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <input type="text" disabled={jobIsCompleted} defaultValue={distance} placeholder="Distance" name="distance" />
                    </div>
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item w5 no-shadow">
                    <span className="waypoint-from">
                      <Icon icon="pin" style={{ marginRight: 0 }} />
                    </span>
                  </div>
                  <div className="new-table--row--item no-side-shadows">
                    <span className="info">From</span>
                  </div>
                  <div className="new-table--row--item col-6 media-sm-col-3">
                    <Autocomplete
                      name="address_from"
                      placeholderText={'* Address you are moving From'}
                      value={address_from || ''}
                      disabled={jobIsCompleted}
                      onChange={handleChangeAddress}
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="new-table--row--item col-3">
                    <input type="text" disabled={jobIsCompleted} value={zip_from} placeholder="Zip From" name="zip_from" onChange={handleChange} />
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item w5 no-shadow">
                    <span className="waypoint-to">
                      <Icon icon="pin" style={{ marginRight: 0 }} />
                    </span>
                  </div>
                  <div className="new-table--row--item no-side-shadows">
                    <span className="info">To</span>
                  </div>
                  <div className="new-table--row--item col-6 media-sm-col-3">
                    <Autocomplete
                      name="address_to"
                      placeholderText={'* Address you are moving To'}
                      value={address_to || ''}
                      disabled={jobIsCompleted}
                      onChange={handleChangeAddress}
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="new-table--row--item col-3">
                    <input type="text" disabled={jobIsCompleted} value={zip_to} placeholder="Zip To" name="zip_to" onChange={handleChange} />
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item justify-content-around">
                    {data?.packing_description?.map((item) => {
                      return (
                        <TooltipTitle title={item.description} key={item.type} top>
                          <RadioButton
                            name="packing_service"
                            title={item.title}
                            current={state.packing_service}
                            value={item.type}
                            onChange={() => actions.handleRadio('packing_service', item.type)}
                          />
                        </TooltipTitle>
                      );
                    })}
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item new-table--row--item__full no-side-shadows">
                    <input type="text" disabled={jobIsCompleted} value={note} name="note" onChange={handleChange} placeholder="Job note" />
                  </div>
                </div>

                {!jobIsCompleted && (
                  <button type="button" disabled={jobIsCompleted} onClick={() => saveChanges(state)} className="update-button">
                    Update
                  </button>
                )}

                {!jobIsCompleted && (
                  <div className="new-table--row">
                    <div className="new-table--row--item new-table--row--item__full no-side-shadows">
                      <a href={packingLink} className="add-button" target="_blank" rel="noopener noreferrer">
                        <Icon icon="add" /> Add/Change packing
                      </a>
                    </div>
                  </div>
                )}

                {packing.length > 0 && (
                  <div className="job-inventory-modal">
                    <div className="job-inventory-modal-body">
                      <div className="inventory-total">
                        <div className="job-modal-category">
                          <div className="job-modal-category-row job-modal-category-row__header">
                            <p className="row-name">Name</p>
                            <span className="w20">Price</span>
                            <span className="w20">Size</span>
                            <span className="w20">Quantity</span>
                          </div>
                          {packing.map((item) => {
                            return (
                              <div className="job-modal-category-row" key={item.id}>
                                <p className="row-name">{item.name}</p>
                                <span className="w20">
                                  <span>{item.price}</span>
                                </span>
                                <span className="w20">
                                  {item.size.height} x {item.size.length} x {item.size.width}
                                </span>
                                <span className="w20">{item.qty}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!jobIsCompleted && (
                  <div className="new-table--row">
                    <div className="new-table--row--item new-table--row--item__full no-side-shadows">
                      <a href={inventoryLink} className="add-button" target="_blank" rel="noopener noreferrer">
                        <Icon icon="add" /> Add/Change inventory
                      </a>
                    </div>
                  </div>
                )}

                {inventory.length > 0 && (
                  <div className="job-inventory-modal">
                    <div className="job-inventory-modal-body">
                      <div className="inventory-total">
                        <div className="job-modal-category">
                          <div className="job-modal-category-row job-modal-category-row__header">
                            <p className="row-name">Name</p>
                            <span className="w20">CF</span>
                            <span className="w20">Qty</span>
                            <span className="w20">Total</span>
                          </div>
                          {inventory.map((item) => {
                            return (
                              <div className="job-modal-category-row" key={item.id}>
                                <p className="row-name">{item.name}</p>
                                <span className="w20">
                                  <span>{item.amount}</span>
                                </span>
                                <span className="w20">{item.qty}</span>
                                <span className="w20">{(item.amount * item.qty).toFixed(2)}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {data?.move_type === 'long_distance' && data.ld_calc && data.storage_calc && data.job_other_services && (
                  <div className="data-list">
                    <div>
                      <p>
                        <span className="title">Pickup</span>
                        <span className="quantity">${data.ld_calc.pickup_total}</span>
                      </p>

                      <p>
                        <span className="title">Storage</span>
                        <span className="quantity"></span>
                      </p>
                      <ul style={{ paddingLeft: 20 }}>
                        <li>
                          <p>
                            <span className="title">Period of storage</span>
                            <span className="quantity">{Number(data.storage_calc.days_length)} days</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="title">First month free</span>
                            <span className="quantity"></span>
                          </p>
                        </li>
                        {Number(data.storage_calc.month_length) > 6 && (
                          <li>
                            <p style={{}}>
                              <span className="title">From second month</span>

                              <span className="quantity">
                                ${(+data.storage_calc.balance / Math.ceil(data.storage_calc.month_length) - 1).toFixed(2)} per month
                              </span>
                            </p>
                          </li>
                        )}
                        <li>
                          <p>
                            <span className="title">Total</span>
                            <span className="quantity">${data.storage_calc.balance}</span>
                          </p>
                        </li>
                      </ul>

                      <p>
                        <span className="title">Delivery</span>
                        <span className="quantity">${data.ld_calc.delivery_total}</span>
                      </p>
                      <p>
                        <span className="title">Total CF</span>
                        <span className="quantity">{data.ld_calc.total_cf}</span>
                      </p>
                      <p className="total">
                        <span className="title">Estimate Total</span>
                        <span className="quantity">
                          $
                          {+data.ld_calc.base_price +
                            +data.ld_calc.additional_price +
                            +data.job_other_services.origin_services_total +
                            +data.job_other_services.destination_services_total}
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {data?.move_type === 'local' && (
                  <div className="data-list">
                    <div>
                      <p>
                        <span className="title">Crew</span>
                        <span className="quantity">
                          {movers} movers, {trucks} truck
                        </span>
                      </p>
                      <p>
                        <span className="title">Rate</span>
                        <span className="quantity">{rate.toFixed(2)} $/h</span>
                      </p>
                      {credit_rate !== 0 && (
                        <p>
                          <span className="title">Credit rate is included in hourly rate</span>
                          <span className="quantity">${company_credit_rate.toFixed(2)}</span>
                        </p>
                      )}
                      {full_packing_rate !== 0 && (
                        <p>
                          <span className="title">Full packing rate</span>
                          <span className="quantity">INCLUDED</span>
                        </p>
                      )}
                      <p>
                        <span className="title">
                          Minimum Estimated move time ${rate} x {estimated_hours}h
                        </span>
                        <span className="quantity">${estimated_hours * rate.toFixed(2)}</span>
                      </p>
                    </div>

                    <div className="mt-3">
                      {travel_money !== 0 && (
                        <p>
                          <span className="title">Travel Time</span>
                          <span className="quantity">${travel_money.toFixed(2)}</span>
                        </p>
                      )}
                      {total_packing !== 0 && (
                        <p>
                          <span className="title">Packing Material</span>
                          <span className="quantity">${total_packing.toFixed(2)}</span>
                        </p>
                      )}
                      {job_fees_elements}
                      {job_charges_elements}

                      {!!manual_charge && (
                        <p>
                          <span className="title">Manual charge</span>
                          <span className="quantity">${manual_charge.toFixed(2)}</span>
                        </p>
                      )}
                    </div>

                    <div className="mt-3">
                      {!!manual_discount && (
                        <p>
                          <span className="title">Discount</span>
                          <span className="quantity">${manual_discount.toFixed(2)}</span>
                        </p>
                      )}
                      {job_discounts_elements}
                    </div>

                    <div className="mt-3">
                      {!!tips && (
                        <p>
                          <span className="title">Tips</span>
                          <span className="quantity">${tips.toFixed(2)}</span>
                        </p>
                      )}
                    </div>

                    <div className="mt-3">
                      <p>
                        <span className="title">Contract Total</span>
                        <span className="quantity">${contract_total.toFixed(2)}</span>
                      </p>
                      {!!addendum_total && (
                        <p>
                          <span className="title">Addendum Total</span>
                          <span className="quantity">${addendum_total.toFixed(2)}</span>
                        </p>
                      )}
                    </div>

                    <p className="total">
                      <span className="title">Estimated cost </span>
                      <span className="quantity">${estimated_cost.toFixed(2)}</span>
                    </p>
                  </div>
                )}

                <div className="message-block">
                  {include && (
                    <div className="message">
                      <p>{include.title}</p>
                      <div>{include.text}</div>
                    </div>
                  )}
                  {extra && (
                    <div className="message">
                      <p>{extra.title}</p>
                      <div>{extra.text}</div>
                    </div>
                  )}
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item new-table--row--item__full new-table--row--item__column no-side-shadows">
                    <b>
                      ALL ESTIMATES ARE BASED ON MINIMUM OR AVERAGE TIME IT TAKES FOR YOUR SPECIFIED MOVE SIZE. EVERY MOVE IS DIFFERENT AND IT IS
                      IMPOSSIBLE TO PREDICT EXACTLY HOW LONG ITS GOING TO TAKE. YOU WILL BE CHARGED FOR ACTUAL TIME FROM ARRIVAL TO LAST ITEM
                      OFFLOADED AND SETUP AT YOUR DESTINATION.
                    </b>
                  </div>
                </div>

                {state.documents && jobIsCompleted && (
                  <div className="documents">
                    <h3>Attachments</h3>
                    <ul>
                      {state.documents.map((item, index) => (
                        <li key={index}>
                          <a href={item.path} target="_blank" rel="noopener noreferrer">
                            <Icon icon="file" className="icon-wrap" />
                            <div className="documents-content">
                              <p className="documents-title">{item.name}</p>
                              <p className="documents-info">Click to view</p>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <div className="new-table--row mobile">
                  {confirmButtonStatuses.includes(state.job_status) && (
                    <div className="menu-button-item">
                      <button type="button" onClick={() => confirmJob(state.job_id)} className="menu-button text-center">
                        Confirm Job
                      </button>
                    </div>
                  )}

                  {rescheduleButtonStatuses.includes(state.job_status) && (
                    <div className="menu-button-item">
                      <button type="button" onClick={handleRescheduleJob} className="menu-button text-center">
                        Reschedule Job
                      </button>
                    </div>
                  )}

                  {bookedButtonStatuses.includes(state.job_status) && Number(state?.deposit) === 0 && (
                    <div className="menu-button-item">
                      <button type="button" onClick={() => bookingJob(state.job_id)} className="menu-button text-center">
                        Book Job
                      </button>
                    </div>
                  )}

                  {cancelButtonStatuses.includes(state.job_status) && (
                    <div className="menu-button-item">
                      <button type="button" onClick={() => cancelJob(state.job_id)} className="menu-button text-center">
                        Cancel Job
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomerEditJob;
