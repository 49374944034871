import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Api from './api/api';

const socket = io('wss://ws2.imover.biz:8083', {
  secure: true,
  transports: ['websocket'],
});

const useFetchData = (jobId, params) => {
  const { showPreloader, hidePreloader } = params;
  const [data, setData] = useState(null);

  useEffect(() => {
    showPreloader();
    Api.getData({ job_id: jobId })
      .then((response) => {
        setData({
          storage_calc: response.data.storage_calc,
          ld_calc: response.data.ld_calc,
          job_other_services: response.data.job_other_services,
          move_type: response.data.move_type,
          deposit: response.data.deposit,
          packing_description: response.data.packing_description,
          job_id: response.data.job_id_hash,
          job_status: response.data.job_status,
          required_pickup: response.data.required_pickup,
          name: response.data.job_data_from.customer_name,
          email: response.data.job_data_from.email,
          phone: response.data.job_data_from.home_phone,
          address_from: response.data.job_data_from.address,
          zip_from: response.data.job_data_from.zip,
          address_to: response.data.job_data_to.address,
          zip_to: response.data.job_data_to.zip,
          distance: (Number(response.data.job_calc.distance_total) - Number(response.data.job_calc.distance_pickup)).toFixed(2),
          note: '',
          extra: response.data.extra,
          include: response.data.include,
          movers: response.data.job_calc.qty_man,
          trucks: 1,
          estimated_cost: Number(response.data.job_calc.estimated_total),
          rate: Number(response.data.job_rate),
          av_time1: response.data.job_calc.estimated_time,
          full_packing_rate: Number(response.data.job_calc.full_packing_rate),
          credit_rate: response.data.job_calc.credit_rate,
          travel_money: response.data.travel_money,
          company_credit_rate: Number(response.data.company_credit_rate),
          av_time2: 0,
          packing: response.data.packing,
          inventory: response.data.inventory,
          coi_approved: response.data.coi_approved,
          total_packing: Number(response.data.total_packing),
          job_fees: response.data.job_fees,
          job_charges: response.data.job_charges,
          manual_charge: Number(response.data.job_calc.manual_plus),
          manual_discount: Number(response.data.job_calc.manual_minus),
          contract_total: Number(response.data.contract_total),
          addendum_total: Number(response.data.addendum_total),
          tips: Number(response.data.job_calc.tips_amount),
          packing_service: response.data.job_calc.packing_service,
          estimated_hours: Number(response.data.job_calc.estimated_time),
          job_discounts: response.data.job_discounts,
          documents: response.data.documents,
        });
      })
      .finally(() => hidePreloader());
  }, [jobId]);

  useEffect(() => {
    const listener = () => {
      if (!jobId) return;

      showPreloader();
      Api.getPackingInventoryData({ job_id: jobId })
        .then((response) => setData({ ...data, packing: response.data.packing, inventory: response.data.inventory }))
        .finally(() => hidePreloader());
    };

    socket.on('packing_update', listener);
    socket.on('inventory_update', listener);
  }, [jobId]);

  return data;
};

export { useFetchData };
